<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.creation }}</h4>
    <div class="admin-form-wrapper">
      <div class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.organization }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="organizationId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="createForm.organizationId"
                    :options="helper.organizations"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    :searchable="true"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                  <template v-slot:option="{ option }">
                    <div>
                      <div class="multiselect-custom-label">{{ option.label }}</div>
                      <div class="multiselect-custom-sublabel">{{ option.address }}</div>
                    </div>
                  </template>
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="organizationId" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.quota }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="total" type="number"
                     v-model="createForm.total"/>
            </div>
            <ErrorMessage name="total" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.create }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import "@vueform/multiselect/themes/default.css";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    Multiselect,
  },
  data() {
    return {
      createForm: {
        organizationId: null,
        total: '',
      },
      helper: {
        organizations: []
      }
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.post('/quota/create', this.createForm).then(({data}) => {
        this.$snotify.success(this.$lang.app.resource_created_successfully);
        if(this.$route.query.type === 'SCHOOL') {
          this.$router.push(`/admin/quotas/${data.message}?type=${this.$route.query.type}`)
        } else {
          this.$router.push(`/admin/quotas?type=${this.$route.query.type}`)
        }
      }).catch(error => {
        if(error.response.status === 400) {
          this.$snotify.error(error.response.data.message);
          actions.setErrors(error.response.data.errors);
        }
      })
    },
    setOrganizations() {
      this.$axios.get('/organization/by-type', {
        params: {
          type: this.$route.query.type,
        }
      }).then(({data}) => {
        this.helper.organizations = this.getParsedOrganizations(data)
      })
    },
    getParsedOrganizations(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({kg: item.nameKG, ru: item.nameRU}),
          address: this.$lang.$translate({kg: item.fullAddressKG, ru: item.fullAddressRU})
        }
      })
    }
  },
  mounted() {
    this.setOrganizations()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
@import '../../../assets/styles/multiselect.css';
</style>